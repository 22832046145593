<template>
  <form @submit.prevent="onSubmit">
    <va-notification
      color="danger"
      v-show="invalid"
      closeable
    >
      <va-badge color="danger">
        {{ $t('notifications.alerts.info') }}
      </va-badge>
      {{ $t('notifications.login.error') }}
    </va-notification>
    <va-input
      color="info"
      v-model="form.email.value"
      type="email"
      :label="$t('auth.email')"
      :error="!!form.email.errors.length"
      :error-messages="translatedErrors(form.email.errors)"
    />

    <va-input
      color="info"
      v-model="form.password.value"
      type="password"
      :label="$t('auth.password')"
      :error="!!form.password.errors.length"
      :error-messages="translatedErrors(form.password.errors)"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      <router-link
        class="ml-1 link"
        :to="{name: 'recover-password'}"
      >{{$t('auth.recover_password')}}</router-link>
    </div>

    <div class="d-flex justify--center mt-3">
      <va-button color="primary">
        <text-loading :loading="loading">{{ $t('auth.login') }}</text-loading>
      </va-button>
    </div>
  </form>
</template>

<script>
import { isFormReady, hasFormErrors } from '../../../services/validator'
import { mapGetters } from 'vuex'
const defaultRoute = '/admin/dashboard'

export default {
  name: 'login',
  data () {
    return {
      loading: false,
      invalid: false,
      preparing: false,
      keepLoggedIn: false,
      form: {
        email: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        password: {
          value: '',
          validate: {
            required: true,
            minLength: 8,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    formReady () {
      return isFormReady(this.form)
    },
  },
  created () {
    this.clearUserData()
  },
  methods: {
    clearUserData () {
      delete this.$http.defaults.headers.common.Authorization
      this.$store.commit('logoutUser')
      this.loading = false
    },
    translatedErrors (errors) {
      const e = []
      for (const error of errors) {
        e.push(this.$t(error.label, error.context))
      }
      return e
    },
    onSubmit () {
      hasFormErrors(this.form)
      if (!this.formReady) {
        return
      }
      this.authenticate()
    },
    async authenticate () {
      this.loading = true
      this.invalid = false

      let request = false
      try {
        request = await this.$http.post('auth/login', {
          email: this.form.email.value,
          password: this.form.password.value,
        })
      } catch (error) {
        this.loginFailed(error)
        return
      }
      this.loginSuccess(request.data)
    },
    async loginSuccess (req) {
      if (!req.data.token) {
        this.loginFailed()
        return
      }

      localStorage.token = req.data.token
      this.$http.defaults.headers.common.Authorization = 'Bearer ' + localStorage.token
      this.$store.commit('loginUser')
      this.preparing = true

      const gotoRoute = defaultRoute
      this.updateUserModules()
      await this.updateUserPermissions()
      /* if (this.currentUser && this.currentUser.hasGroup(6)) {
        if (this.currentUser.groups.length === 1) {
          gotoRoute = '/academy/enrolled'
          window.location.href = '/academy/enrolled'
          return
        }
      } */
      this.$router.push(this.$route.query.redirect || gotoRoute)
    },
    async updateUserPermissions () {
      let p = null
      try {
        p = await this.$http.get('permissions')
      } catch (e) {
        console.error('Error while getting permissions', e)
        return
      }

      this.$store.commit('updatePermissions', p.data.data)
    },
    async updateUserModules () {
      let p = null
      try {
        p = await this.$http.get('regions/modules/user')
      } catch (e) {
        console.error('Error while getting permissions', e)
        return
      }

      this.$store.commit('updateModules', p.data.data)
    },
    loginFailed (error) {
      if (error && error.response.status !== 401) {
        this.showToast(this.$t('notifications.login.fail'), { icon: 'fa-times' })
      }

      if (error.response.status === 401) {
        this.invalid = true
      }

      this.clearUserData()
    },
  },
}
</script>

<style lang="scss">
.link,
.link:visited {
  color: var(--brand-primary) !important;
}
</style>
